  import React,{ useEffect, useMemo, useState, useReducer } from 'react';
  import PageTitle from "../../layouts/PageTitle";
  import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
  import { KOLOM } from './Columns';
  import { GlobalFilter } from './GlobalFilter'; 
  import './filtering.css';
  import { UseAPI } from '../../../services/UseAPI';
  import { Button, Dropdown, Modal, Table } from 'react-bootstrap';
  import { Link, useHistory } from 'react-router-dom';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { faFilter, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
  import Swal from 'sweetalert2';
  import Select from "react-select";
import { ExportData } from '../../../services/Utils';



  export const Bangunan = () => {
    const [optionData,setOptionData] = useState([]);
    const [field, setField] = useState([])
    
    const [sourcedata, setSourceData] = useState([]);
    const columns = useMemo( () => KOLOM, [] )
    let history = useHistory();
    const handleRowAction = (row) => {
      console.log(row);
      history.push("/bangunan/edit/"+row.id);
    }
    const handleRowActionDelete = (row) => {
      Swal.fire({
      title: "Anda yakin ?",
      text: "Data yang sudah dihapus tidak dapat dikembalikan",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya"
      }).then((result) => {
        if (result.isConfirmed) {
          UseAPI(`bangunan/${row.id}`,{},'DELETE').then((resp) => {
            if (resp.status === 'ok') { 
            setSourceData(resp.data);
            Swal.fire({
              title: resp.pesan,
              text: "Berhasil dihapus",
              icon: "success"
            });

            } else {
            Swal.fire({
              title: resp.pesan,
              text: "Gagal dihapus",
              icon: "danger"
            });
            }
          })	
        }
      });	
      console.log(row);
    }

    useEffect(() => {
      let bangunan = [];
      UseAPI('bangunan',{},'GET').then((resp)=>{
      console.log("cara_perolehan",resp);
      setSourceData(resp);
      })       
    }, []);  
    
    const data = useMemo( () => sourcedata, [sourcedata] )
    const tableInstance = useTable({
      columns,		
      data,	
      initialState : {pageIndex : 0},
      handleRowAction,
      handleRowActionDelete
    }, useFilters, useGlobalFilter, usePagination)
    const { 
      getTableProps, 
      getTableBodyProps, 
      headerGroups,
      prepareRow,
      state,
      page,
      gotoPage,
      pageCount,
      pageOptions,
      nextPage,
      previousPage,
      canNextPage,
      canPreviousPage,
      setGlobalFilter,
    } = tableInstance
    const {globalFilter, pageIndex} = state

    const filterData = () => {
      console.log("test");
    }
      
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => {
      setShowModal(false);
    }
    const handleShow = () => {
      setShowModal(true);
    }
 

    const optionChange = (e) => {
      console.log(e);
      setOptionData(values => ({...values, [e.name]: e.value}))
    }    

    return(
      <>
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3 row">
                <label className="col-form-label">{'Ruangan'}</label>
                <Select
                    // value={ruangan[inputs.bidang_id]}
                    onChange={optionChange}
                    options={field.ruangan_id && field.ruangan_id.masterdata}
                    name={'ruangan_id'}
                    id={'ruangan_id'}
                />                                         
            </div>          


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>      
        <PageTitle activeMenu="Daftar" motherMenu="bangunan" />
        <div className="card">
          <div className="card-header">
          <h4 className="card-title">Daftar Bangunan</h4>
            <div className="d-flex align-items-center justify-content-between">
              <Dropdown className="dropdown">
                <Dropdown.Toggle as="div" className="btn-link i-false">
                <FontAwesomeIcon icon={faLayerGroup} className="fa-2x" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                  <Dropdown.Item as={Link} to={'/bangunan/tambah'} className="dropdown-item">Baru</Dropdown.Item>
                  <Dropdown.Item onClick={() => ExportData('bangunan') } className="dropdown-item">Export</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleShow(true) } className="dropdown-item">Filter</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>            

          </div>
          <div className="card-body">
            <div className="table-responsive">
              {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
              {sourcedata && (
              <Table {...getTableProps()} striped bordered hover size='sm'>
                <thead>
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()}>
                          {column.render('Header')}
                          {column.canFilter ? column.render('Filter') : null}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead> 
                <tbody {...getTableBodyProps()} className="" >
                
                  {page.map((row) => {
                    prepareRow(row)
                    return(
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return <td {...cell.getCellProps()} style={{padding:5}}> {cell.render('Cell')} </td>
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              )}
              <div className="d-flex justify-content-between">
                <span>
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{''}
                </span>
                <span className="table-index">
                  Go to page : {' '}
                  <input type="number" 
                    className="ml-2"
                    defaultValue={pageIndex + 1} 
                    onChange = {e => { 
                      const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
                      gotoPage(pageNumber)
                    } } 
                  />
                </span>
              </div>
              <div className="text-center mb-3">	
                <div className="filter-pagination  mt-3">
                  <button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                  
                  <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                    Previous
                  </button>
                  <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                    Next
                  </button>
                  <button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
    
  }
  export default Bangunan;