import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup } from 'react-bootstrap';

export const KOLOM = [
	{
		Header: '#',
		Cell: ({row}) => (
			<>
				{parseInt(row.id)+1}
			</>
		)
	}	
	,{
		Header:'Nama',
		Footer:'Nama',
		accessor:'nama_bangunan',
		Filter:ColumnFilter
	}
	,{
		Header:'Kategori',
		Footer:'Kategori',
		accessor:'nama_master_kode_barang',
		Filter:ColumnFilter
	}
	,{
		Header:'Luas (M2)',
		Footer:'Luas (M2)',
		accessor:'luas_bangunan',
		Filter:ColumnFilter
	}
	,{
		Header:'Peresmian',
		Footer:'Peresmian',
		accessor:'tanggal_peresmian',
		Filter:ColumnFilter
	}
	,{
		Header: 'Aksi',
		Cell: ({ row, handleRowAction, handleRowActionDelete }) => (
			<>
				<ButtonGroup className="me-2" aria-label="First group">
				<Button variant="primary" onClick={() => handleRowAction(row.original)} size='sm'>
							<FontAwesomeIcon icon={ faEdit } />
				</Button>
				<Button variant="danger" onClick={() => handleRowActionDelete(row.original)} size='sm'>
							<FontAwesomeIcon icon={ faTrash } />
				</Button>
				</ButtonGroup>

			</>
		),
	}
]
export const KOLOM_TANAH = [
	{
		Header: '#',
		Cell: ({row}) => (
			<>
				{parseInt(row.id)+1}
			</>
		)
	}	
	,{
		Header:'Kode Lokasi',
		accessor:'kode_lokasi',
		Filter:ColumnFilter
	}
	,{
		Header:'Kode Aset',
		accessor:'kode_asset',
		Filter:ColumnFilter
	}
	,{
		Header:'Wilayah',
		accessor:'nama_wilayah',
		Filter:ColumnFilter
	}
	,{
		Header:'Pemegang Hak',
		accessor:'pemegang_hak',
		Filter:ColumnFilter
	}
	,{
		Header:'Luas (M2)',
		accessor:'luas',
		Filter:ColumnFilter
	}
	,{
		Header:'Jumlah Bangunan',
		accessor:'total_bangunan',
		Filter:ColumnFilter
	}
	,{
		Header: 'Aksi',
		Cell: ({ row, handleRowAction, handleRowActionDelete }) => (
			<>
				<ButtonGroup className="me-2" aria-label="First group">
				<Button variant="primary" onClick={() => handleRowAction(row.original)} size='sm'>
					Pilih
				</Button>
				</ButtonGroup>

			</>
		),
	}
]


