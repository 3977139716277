import { faAdd, faBars, faCheck, faFilter, faLayerGroup, faPen, faQuestion, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Modal, Table } from "react-bootstrap";

import { Link } from "react-router-dom";

const DaftarBarang = (props) => {  
  
  const [data, setData] = useState([]);
  const [test, settest] = useState(0);

  useEffect(async () => { 
    // console.log("props.activePag",props.activePag);
    // console.log("data",data);
    // setData(props.data)

    // UseAPI('tanah/?from='+(props.activePag.current*window.dataLimit)+'&'+props.where,{},'GET').then((resp)=>{
    //   setData(resp.data);
    //   console.log("resp_from_table",resp);
    // })

  }, []);



  // paggination
  // let paggination = Array(Math.ceil(data.length / sort))
  //   .fill()
  //   .map((_, i) => i + 1);

  return (    
    <div className="col-12">
      <div className="card">
        <div className="card-header">
            <div className="pe-3 me-auto mb-sm-0 mb-3">
                <h4 className="fs-20 text-black mb-1 font-w700">{props.title}</h4>
            </div>
            <div className="d-flex align-items-center justify-content-between">
                <Button variant="link" className="mb-2 me-2 btn-sm" onClick={() => props.showFilterModal({type:'basicModal'})}>
                  <FontAwesomeIcon icon={ faFilter } className="fa-2x"/>
                </Button>

                <Dropdown className="dropdown">
                    <Dropdown.Toggle as="div" className="btn-link i-false">
                      <FontAwesomeIcon icon={faLayerGroup} className="fa-2x" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                        <Dropdown.Item as={Link} to={`${props.link}/tambah`} className="dropdown-item">Baru</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>            
        </div>
        <div className="card-body">
          <div className="w-100 table-responsive">
            <div id="kategoriTable" className="dataTables_wrapper">
              <Table striped hover size="sm" responsive="true"
                id="example5"
                className="w-100"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th
                    >                     
                      #
                    </th>
                    <th
                    >                     
                      Ruangan
                    </th>
                    <th
                    >                     
                      Nama
                    </th>
                    <th
                    >                     
                      Perolehan
                    </th>
                    <th
                    > 
                      Kondisi
                    </th>
                    <th
                    >
                      Tanggal Perolehan
                    </th>
                    <th
                    >
                      Aksi
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.data && props.data.map((item,key)=>(
                    <tr key={key} role="row">
                      <td>{key+1}</td>
                      <td>{`${item.nama_bangunan} - ${item.nama_ruangan}`}</td>
                      <td>{item.nama_barang}</td>
                      <td>{item.nama_cara_perolehan}</td>
                      <td>{item.nama_kondisi}</td>
                      <td>{item.tanggal_perolehan}</td>
                      <td>
                        <Dropdown className="dropdown ms-auto text-right">
                          <Dropdown.Toggle
                            variant=""
                            className="btn-link i-false"
                            data-toggle="dropdown"
                          >
                          <FontAwesomeIcon icon={faBars} size="1x"/>  
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                            <Dropdown.Item as={Link} to={`/${props.link}/edit/${item.barang_id}`}><FontAwesomeIcon icon={faPen} className="me-2" /> Edit</Dropdown.Item>
                            <Dropdown.Item as={Link} to={'#'} onClick={() => props.onDelete(item.barang_id)}><FontAwesomeIcon icon={faTrash} className="me-2" /> Hapus</Dropdown.Item>
                          </Dropdown.Menu> 
                        </Dropdown>
                      </td>
                    </tr>
                  ))}

                </tbody> 
              </Table>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DaftarBarang;
